<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <card class="form-horizontal">
          <!-- <h5 slot="header">Booking</h5> -->
          <tabs value="Description" tab-content-classes="text-center">
            <tab-pane title="Client" key="client">
              <h4>Client Details</h4>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Company Name</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    :disabled="isDisabled"
                    name="company_name"
                    v-validate="modelValidations.company_name"
                    :error="getError('company_name')"
                    v-model="model.company_name"
                  ></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Sales Person</label>
                <div class="col-sm-4">
                  <el-select class="select-default" placeholder v-model="model.salesteam_id">
                    <el-option
                      v-for="option in salesTeam"
                      class="select-primary"
                      :value="option.value"
                      :label="option.name"
                      :key="option.name"
                    ></el-option>
                  </el-select>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Action Outstanding</label>
                <div class="col-sm-2">
                  <el-select
                    class="select-default"
                    size="large"
                    placeholder
                    v-model="model.action_outstanding"
                  >
                    <el-option
                      v-for="option in YesNo"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label"
                    ></el-option>
                  </el-select>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Heat</label>
                <div class="col-sm-2">
                  <el-select
                    class="select-default"
                    size="large"
                    placeholder
                    v-model="model.contact_heat"
                  >
                    <el-option
                      v-for="option in heat"
                      class="select-primary"
                      :value="option.value"
                      :label="option.text"
                      :key="option.text"
                    ></el-option>
                  </el-select>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Account Code</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="account_code"
                    v-validate="modelValidations.account_code"
                    :error="getError('account_code')"
                    v-model="model.account_code"
                  ></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">VAT Number</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="vatnumber"
                    v-validate="modelValidations.vatnumber"
                    :error="getError('vatnumber')"
                    v-model="model.vatnumber"
                  ></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Exported to Accounts</label>
                <div class="col-sm-2">
                  <el-select
                    class="select-default"
                    size="large"
                    placeholder
                    v-model="model.exportedtosage"
                  >
                    <el-option
                      v-for="option in YesNo"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label"
                    ></el-option>
                  </el-select>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Telephone</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="tel"
                    v-validate="modelValidations.tel"
                    :error="getError('tel')"
                    v-model="model.tel"
                  ></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Fax</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="fax"
                    v-validate="modelValidations.fax"
                    :error="getError('fax')"
                    v-model="model.fax"
                  ></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Mobile</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="mobile"
                    v-validate="modelValidations.mobile"
                    :error="getError('mobile')"
                    v-model="model.mobile"
                  ></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Email</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="email"
                    v-validate="modelValidations.email"
                    :error="getError('email')"
                    v-model="model.email"
                  ></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Address Line 1</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="address_line_1"
                    v-validate="modelValidations.address_line_1"
                    :error="getError('address_line_1')"
                    v-model="model.address_line_1"
                  ></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Address Line 2</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="address_line_2"
                    v-validate="modelValidations.address_line_2"
                    :error="getError('address_line_2')"
                    v-model="model.address_line_2"
                  ></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Address Line 3</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="address_line_3"
                    v-validate="modelValidations.address_line_3"
                    :error="getError('address_line_3')"
                    v-model="model.address_line_3"
                  ></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Town</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="address_town"
                    v-validate="modelValidations.address_town"
                    :error="getError('address_town')"
                    v-model="model.address_town"
                  ></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">County</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="address_county"
                    v-validate="modelValidations.address_county"
                    :error="getError('address_county')"
                    v-model="model.address_county"
                  ></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Country</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="address_country"
                    v-validate="modelValidations.address_country"
                    :error="getError('address_country')"
                    v-model="model.address_country"
                  ></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Post Code</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="address_post_code"
                    v-validate="modelValidations.address_post_code"
                    :error="getError('address_post_code')"
                    v-model="model.address_post_code"
                  ></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <!-- <label class="col-md-3"></label> -->
                <div class="col-md-9">
                  <p-button type="info" @click.prevent="validate">Save</p-button>
                </div>
              </div>
            </tab-pane>

            <tab-pane title="Notes" key="notes">
              <div class="row">
                <div class="col-sm-9">
                  <div class="pull-left">
                    <p class="text-muted">
                      Notes are aggregated from grouped clients.
                      There are {{duplicatesSelectedCount}} in your group of a possible {{duplicatesCount}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="pull-right">
                    <div class="btn-group">
                      <p-button type="primary" @click="handleNew()">New Note</p-button>
                    </div>
                  </div>
                </div>
              </div>
              <Notes v-bind:id="id" mode="client" v-bind:refreshData="refresNotesData"></Notes>
            </tab-pane>
            <tab-pane title="Contacts" key="contacts">
              <div class="row">
                <div class="col-sm-12">
                  <div class="pull-right">
                    <div class="col-md-12">
                      <p-button type="primary" @click="handleNewContact()">New Contact</p-button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <vue-good-table
                  :theme="tableTheme"
                  :columns="contactColumns"
                  :rows="clientContacts"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                      <p-button
                        type="success"
                        size="sm"
                        icon
                        @click="handleEditContact(props.$index, props.row)"
                      >
                        <i class="fa fa-edit"></i>
                      </p-button>
                    </span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                </vue-good-table>
              </div>
            </tab-pane>
            <tab-pane title="Bookings" key="bookings">
              <div class="row">
                <div class="col-sm-12">
                  <div class="pull-right">
                    <div class="col-md-12">
                      <p-button type="primary" @click="handleNewBooking()">New Booking</p-button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <vue-good-table
                  :theme="tableTheme"
                  :columns="bookingColumns"
                  :rows="clientBookings"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                      <p-button
                        type="success"
                        size="sm"
                        icon
                        @click="handleEditBooking(props.$index, props.row)"
                      >
                        <i class="fa fa-edit"></i>
                      </p-button>
                    </span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                </vue-good-table>
              </div>
            </tab-pane>
            <tab-pane title="Pipeline" key="pipeline">
              <div class="row">
                <div class="col-sm-12">
                  <div class="pull-right">
                    <div class="col-md-12">
                      <p-button type="primary" @click="handleNewPipeline()">New Pipeline Booking</p-button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <vue-good-table
                  :theme="tableTheme"
                  :columns="pipelineColumns"
                  :rows="clientPipeline"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                      <p-button
                        type="success"
                        size="sm"
                        icon
                        @click="handleEditPipeline(props.$index, props.row)"
                      >
                        <i class="fa fa-edit"></i>
                      </p-button>
                    </span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                </vue-good-table>
              </div>
            </tab-pane>

            <tab-pane :title="duplicatesTitle" key="clientgrouping">
              <div class="row">
                <div class="col-sm-9">
                  <div class="pull-left">
                    <p
                      class="text-muted"
                    >Use the checkboxes to select clients that you want to group. Then click Group Selected Clients</p>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="pull-right">
                    <div class="btn-group">
                      <p-button type="primary" @click="groupClients()">Group Selected Clients</p-button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <vue-good-table
                  :theme="tableTheme"
                  ref="clientDuplicatesTable"
                  :columns="clientDuplicateColumns"
                  :rows="clientDuplicates"
                  :selectOptions="{
                      enabled: true,
                      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                      selectionInfoClass: 'custom-class',
                      selectionText: 'rows selected',
                      clearSelectionText: 'clear',
                    }"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                      <!-- <p-button type="success" size="sm" icon @click="handleEditClient(props.$index, props.row)">
                          <i class="fa fa-edit"></i>
                      </p-button>-->
                    </span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                </vue-good-table>
              </div>
            </tab-pane>
          </tabs>
        </card>
      </div>
    </div>
    <!-- Add Note Modal -->
    <div>
      <modal :show.sync="modals.newNoteModal" headerClasses="justify-content-center">
        <h5 slot="header" class="title title-up">Add Note</h5>
        <form>
          <div class="form-group">
            <label>Contact</label>
            <el-select
              class="select-default"
              size="large"
              placeholder
              v-model="newNote.contact_guid"
            >
              <el-option
                v-for="option in clientContacts"
                class="select-primary"
                :value="option.guid"
                :label="option.contact"
                :key="option.contact"
              ></el-option>
            </el-select>
          </div>
          <div class="form-group">
            <label>Note</label>
            <el-input type="textarea" rows="4" v-model="newNote.ad_event_notes"></el-input>
          </div>
          <div class="form-group">
            <label>Diary Date</label>
            <el-date-picker
              v-model="newNote.action_date"
              type="datetime"
              placeholder="Select date and time"
              v-bind:default-time="timeNow"
            ></el-date-picker>
          </div>
          <div class="form-group">
            <label>Diary Type</label>
            <el-select class="select-default" placeholder v-model="newNote.event_type">
              <el-option
                v-for="option in eventType"
                class="select-primary"
                :value="option.value"
                :label="option.text"
                :key="option.text"
              ></el-option>
            </el-select>
          </div>

          <div class="form-group">
            <div class="col-sm-12">
              <div class="pull-right">
                <div class="col-md-12">
                  <p-button type="default" @click="handleNewNoteCancel()">Cancel</p-button>
                  <p-button type="primary" @click="saveNewNote()">Save</p-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </modal>
    </div>
  </div>
</template>

<script>
import * as CONSTS from "../../../../../consts/constants";
import { Tabs, TabPane, Card, Modal } from "src/components/UIComponents";
import swal from "sweetalert2";
import {
  DatePicker,
  TimeSelect,
  Slider,
  Tag,
  Input,
  Button,
  Select,
  Option
} from "element-ui";
import Notes from "src/components/Maketime/Sales/Notes.vue";
import { mapGetters } from "vuex";
var numeral = require("numeral");

// create a dictionary for localised labels in forms
const dictionary = {
  en: {
    attributes: {
      company_name: "company name",
      ext_ref: "external reference",
      account_code: "account code",
      tel: "telephone",
      fax: "fax",
      mobile: "mobile",
      email: "email",
      address_line_1: "address line 1",
      address_line_2: "address line 2",
      address_line_3: "address line 3",
      address_town: "town",
      address_county: "county",
      address_country: "country",
      address_post_code: "post code",
      vatnumber: "VAT number"
    }
  }
};

export default {
  computed: {
    ...mapGetters([
      "currentUser",
      "userSelectedProduction",
      "userSelectedProductionInt",
      "userSelectedProductionCcy",
      "tableTheme"
    ]),
    isDisabled() {
      // evaluate whatever you need to determine disabled here...
      return this.mode == CONSTS.UPDATE ? true : false;
      // return ;
    }
  },
  data() {
    return {
      refresNotesData: 0,
      // contacts
      contactColumns: [
        {
          label: "Actions",
          field: "actions"
        },
        {
          field: "contact",
          label: "Contact",
          tdClass: "tablecell",
          thClass: "tablecell"
        },
        {
          field: "tel",
          label: "Telephone",
          tdClass: "tablecell",
          thClass: "tablecell"
        },
        {
          field: "email",
          label: "Email",
          tdClass: "tablecell",
          thClass: "tablecell"
        }
      ],
      // pipeline
      pipelineColumns: [
        {
          field: "date_booked",
          label: "Booking Date",
          formatFn: this.formatDate,
          tdClass: "tablecell",
          thClass: "tablecell",
          filterOptions: {
            enabled: true,
            placeholder: "Filter Sales Person"
          }
        },
        {
          field: "package_name",
          label: "Package",
          tdClass: "tablecell",
          thClass: "tablecell",
          filterOptions: {
            enabled: true,
            placeholder: "Filter Sales Person"
          }
        },
        {
          field: "price",
          label: "Price",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatPrice,
          type: "decimal",
          filterOptions: {
            enabled: true,
            placeholder: "Filter"
          }
        },
        {
          label: "Actions",
          field: "actions"
        }
      ],

      // bookings
      bookingColumns: [
        {
          field: "date_booked",
          label: "Booking Date",
          formatFn: this.formatDate,
          tdClass: "tablecell",
          thClass: "tablecell",
          filterOptions: {
            enabled: true,
            placeholder: "Filter Sales Person"
          }
        },
        {
          field: "ad_type",
          label: "Booking Type",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatType,
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Filter",
            filterDropdownItems: CONSTS.strings.ad_type
          }
        },
        {
          field: "package_name",
          label: "Package",
          tdClass: "tablecell",
          thClass: "tablecell",
          filterOptions: {
            enabled: true,
            placeholder: "Filter Sales Person"
          }
        },
        {
          field: "vendortxcode",
          label: "Reference",
          tdClass: "tablecell",
          thClass: "tablecell",
          filterOptions: {
            enabled: true,
            placeholder: "Filter Sales Person"
          }
        },
        {
          field: "price",
          label: "Price",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatPrice,
          type: "decimal",
          filterOptions: {
            enabled: true,
            placeholder: "Filter"
          }
        },
        {
          label: "Actions",
          field: "actions"
        }
      ],

      clientDuplicateColumns: [
        {
          field: "company_name",
          label: "Client",
          tdClass: "tablecell",
          thClass: "tablecell"
        },
        {
          field: "email",
          label: "Email",
          tdClass: "tablecell",
          thClass: "tablecell"
        },
        {
          field: "address_line_1",
          label: "Address Line 1",
          tdClass: "tablecell",
          thClass: "tablecell"
        }
      ],

      newNote: {
        client_id: 0,
        user_id: 0,
        production_id: 0,
        ad_event_date: null,
        action_date: null,
        contact_id: 0,
        event_type: 0,
        ad_event_notes: "",
        contact_guid: ""
      },

      modals: {
        newNoteModal: false
      },

      mode: CONSTS.UPDATE,
      id: "",
      duplicatesCount: 0,
      duplicatesSelectedCount: 0,
      duplicatesTitle: "Client Group",
      timeNow: null,
      groupedClientIds: [],
      clientProductions: [],
      subPublications: [],
      clientParticipants: [],
      salesTeam: [],
      clientContacts: [],
      clientBookings: [],
      clientPipeline: [],
      clientDuplicates: [],
      heat: CONSTS.strings.heat,
      YesNo: CONSTS.strings.no_yes_label,
      eventType: CONSTS.strings.event_type,
      model: {
        // client
        company_name: "",
        salesteam_id: 0,
        action_outstanding: 0,
        contact_heat: 0,
        ext_ref: "",
        account_code: "",
        exportedtosage: 0,
        tel: "",
        fax: "",
        mobile: "",
        email: "",
        address_line_1: "",
        address_line_2: "",
        address_line_3: "",
        address_town: "",
        address_county: "",
        address_country: "",
        address_post_code: ""
      },
      modelValidations: {
        company_name: {
          required: true,
          max: 80
        },
        account_code: {
          max: 8
        },
        tel: {
          max: 30
        },
        fax: {
          max: 30
        },
        mobile: {
          max: 30
        },
        email: {
          required: true,
          email: true,
          max: 80
        },
        address_line_1: {
          max: 90
        },
        address_line_2: {
          max: 90
        },
        address_line_3: {
          max: 90
        },
        address_town: {
          max: 40
        },
        address_county: {
          max: 40
        },
        address_country: {
          max: 30
        },
        address_post_code: {
          max: 40
        },
        vatnumber: {
          max: 30
        }
      }
    };
  },
  created() {
    if (this.$route.params.id === undefined) {
      console.log("new prod");
      this.mode = CONSTS.NEW;
    } else {
      this.id = this.$route.params.id;
      this.mode = CONSTS.UPDATE;
    }
    // set up a dictionary for the EN form labels
    this.$validator.localize(dictionary);
  },
  mounted() {
    // main record

    if (this.mode == CONSTS.UPDATE) {
      this.$http
        .get("sales/client/" + this.id + "/details")
        .then(response => {
          if (
            response.data.hasOwnProperty("apiresponse") &&
            response.data.apiresponse.status !== "success"
          ) {
            this.$notify({
              message:
                "There was a problem loading the client. Please try again. [1]",
              type: "warning"
            });
          } else {
            this.model = response.data;

            this.getSalesTeam();
            this.getClientContacts();
            this.getClientBookings();
            this.getClientPipeline();
            this.getPotentialClientDuplicates();
          }
        })
        .catch(error => {
          // console.log(error)
          this.$notify({
            message:
              "There was a problem loading the client. Please try again. [2]",
            type: "warning"
          });
        });
    }
  },
  components: {
    Notes,
    Card,
    TabPane,
    Tabs,
    Modal,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Slider.name]: Slider,
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    formatDate(value) {
      return this.$moment(value).format("DD-MM-YYYY");
    },

    handleNewBooking() {
      // pass the production id so that it can't be changed by the global drop down in a new booking
      this.$router.push({
        name: "Booking",
        params: {
          id: 0,
          client_id: this.model.client_id,
          client_guid: this.id,
          production_id: this.userSelectedProductionInt,
          company_name: this.model.company_name,
          bookingmode: CONSTS.BOOKING
        }
      });
    },

    handleNewPipeline() {
      // pass the production id so that it can't be changed by the global drop down in a new booking
      this.$router.push({
        name: "Booking",
        params: {
          id: 0,
          client_id: this.model.client_id,
          client_guid: this.id,
          production_id: this.userSelectedProductionInt,
          company_name: this.model.company_name,
          bookingmode: CONSTS.PIPELINE
        }
      });
    },

    saveNewNote() {
      this.newNote.client_id = this.model.client_id;
      this.newNote.user_id = this.currentUser.userInt;
      this.newNote.production_id = this.userSelectedProductionInt;
      this.newNote.ad_event_date = this.$moment().format("YYYY-MM-DD HH:mm:ss");

      if (this.newNote.action_date) {
        this.newNote.action_date = this.$moment(
          this.newNote.action_date
        ).format("YYYY-MM-DD HH:mm:ss");
      }

      let vm = this;

      this.$http
        .post("sales/note/", this.newNote)
        .then(function(response) {
          if (response.data.apiresponse.status !== "success") {
            vm.$notify({
              message:
                "There was a problem saving the note. Please try again. Error code: " +
                response.data.apiresponse.code,
              type: "warning"
            });
          } else {
            vm.modals.newNoteModal = false;
            vm.refresNotesData++; // trigger a notes refresh

            vm.newNote.action_date = null;
            vm.newNote.contact_id = 0;
            vm.newNote.event_type = 0;
            vm.newNote.ad_event_notes = "";

            vm.$notify({
              message: "Note saved",
              type: "success"
            });
          }
        })
        .catch(function(error) {
          console.log(error);
          vm.$notify({
            message: "There was a problem saving the note. Please try again.",
            type: "warning"
          });
        });
    },
    getClientContacts() {
      let vm = this;
      this.$http
        .get("sales/client/" + this.id + "/contact")
        .then(response => (this.clientContacts = response.data))
        .catch(error => {
          // console.log(error)
          vm.$notify({
            message:
              "There was a problem loading the client contacts. Please try again.",
            type: "warning"
          });
        });
    },

    searchArray: function(array, key, prop) {
      // Optional, but fallback to key['name'] if not selected
      prop = typeof prop === "undefined" ? "name" : prop;

      for (var i = 0; i < array.length; i++) {
        if (array[i][prop] === key) {
          return array[i];
        }
      }
      return null;
    },
    getClientBookings() {
      let vm = this;
      this.$http
        .get("sales/client/" + this.id + "/booking")
        .then(response => (this.clientBookings = response.data))
        .catch(error => {
          // console.log(error)
          vm.$notify({
            message:
              "There was a problem loading the client bookings. Please try again.",
            type: "warning"
          });
        });
    },
    getClientPipeline() {
      let vm = this;
      this.$http
        .get("sales/client/" + this.id + "/pipeline")
        .then(response => (this.clientPipeline = response.data))
        .catch(error => {
          // console.log(error)
          vm.$notify({
            message:
              "There was a problem loading the client pipeline. Please try again.",
            type: "warning"
          });
        });
    },
    formatType: function(value) {
      let obj = this.searchArray(CONSTS.strings.ad_type, value, "value");
      if (obj) {
        return obj.text;
      }
    },
    formatPrice: function(value) {
      let financialFormat = new String("0,0.00");
      return (
        this.userSelectedProductionCcy + numeral(value).format(financialFormat)
      );
    },
    getSalesTeam() {
      let vm = this;
      this.$http
        .get("sales/salesperson")
        .then(response => (this.salesTeam = response.data))
        .catch(error => {
          // console.log(error)
          vm.$notify({
            message:
              "There was a problem loading the sales team. Please try again.",
            type: "warning"
          });
        });
    },

    getPotentialClientDuplicates() {
      let vm = this;
      this.$http
        .get("/sales/client/" + this.id + "/duplicates")
        .then(response => {
          this.clientDuplicates = response.data;

          // set the checkbox if the client is in the group
          // vgtSelected = true
          vm.calculateClientGroupStats();
        })
        .catch(error => {
          // console.log(error)
          vm.$notify({
            message:
              "There was a problem loading the client duplicates. Please try again.",
            type: "warning"
          });
        });
    },

    handleEditBooking(index, row) {
      try {
        this.$router.push({
          name: "Booking",
          params: { bookingmode: CONSTS.BOOKING, id: row.guid }
        });
      } catch (e) {
        console.log(e);
      }
    },
    handleEditPipeline(index, row) {
      try {
        this.$router.push({
          name: "Booking",
          params: { bookingmode: CONSTS.PIPELINE, id: row.guid }
        });
      } catch (e) {
        console.log(e);
      }
    },

    updateRecord() {
      let vm = this;

      this.$http
        .put("sales/client/" + this.id, this.model)
        .then(function(response) {
          if (response.data.apiresponse.status !== "success") {
            vm.$notify({
              message:
                "There was a problem saving the client. Please try again. Error code: " +
                response.data.apiresponse.code,
              type: "warning"
            });
          } else {
            vm.$notify({
              message: "client saved",
              type: "success"
            });
          }
        })
        .catch(function(error) {
          console.log(error);
          vm.$notify({
            message: "There was a problem saving the client. Please try again.",
            type: "warning"
          });
        });
    },

    insertClient() {
      let vm = this;

      alert("not implemented yet!");

      // // convert the JS ISO date to mysql datetime
      // this.model.production_forms_close = this.$moment(this.model.production_forms_close).format('YYYY-MM-DD HH:mm:ss')

      // this.$http.post( 'common/production', this.model )
      //   .then(function (response) {

      //     if (response.data.apiresponse.status !== 'success'){

      //       vm.$notify({
      //         message: 'There was a problem saving the client. Please try again. Error code: ' + response.data.apiresponse.code,
      //         type: 'warning'
      //       })
      //     } else {

      //       console.log(response.data.apiresponse.keys.guid)

      //       vm.id = response.data.apiresponse.keys.guid
      //       vm.mode = CONSTS.UPDATE

      //       vm.$notify({
      //         message: 'Production saved',
      //         type: 'success'
      //       })
      //     }
      //     })
      //   .catch(function (error) {
      //     console.log(error)
      //     vm.$notify({
      //       message: 'There was a problem saving the client. Please try again.',
      //       type: 'warning'
      //     })
      //   })
    },
    validate() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          if (this.mode == CONSTS.UPDATE) {
            this.updateRecord();
          } else {
            this.insertClient();
          }
        } else {
          this.$notify({
            message: "Please correct the validation issues and resubmit.",
            type: "warning"
          });
        }
      });
    },
    handleNewNoteCancel() {
      this.modals.newNoteModal = false;
    },
    handleNew() {
      this.timeNow = "['" + this.$moment().format("HH:mm:ss") + "']";
      this.modals.newNoteModal = true;
    },

    calculateClientGroupStats() {
      this.duplicatesCount = this.duplicatesSelectedCount = 0;

      if (this.clientDuplicates) {
        this.duplicatesCount = this.clientDuplicates.length;

        for (let i = 0; i < this.duplicatesCount; i++) {
          if (this.clientDuplicates[i].vgtSelected)
            this.duplicatesSelectedCount++;
        }
      }

      this.duplicatesTitle =
        "Client Group [" +
        this.duplicatesSelectedCount +
        "/" +
        this.duplicatesCount +
        "]";
    },
    groupClients() {
      this.groupedClientIds = [];

      for (
        var i = 0;
        i < this.$refs["clientDuplicatesTable"].selectedRows.length;
        i++
      ) {
        let obj = {
          guid: this.$refs["clientDuplicatesTable"].selectedRows[i].guid
        };
        this.groupedClientIds.push(obj);
      }

      let vm = this;

      this.$http
        .post("/sales/client/" + this.id + "/group", this.groupedClientIds)
        .then(function(response) {
          if (
            response.data.hasOwnProperty("apiresponse") &&
            response.data.apiresponse.status !== "success"
          ) {
            vm.$notify({
              message:
                "There was a problem saving the client group. Please try again. Error code: " +
                response.data.apiresponse.code,
              type: "warning"
            });
          } else {
            vm.$notify({
              message: "Client group saved",
              type: "success"
            });

            // force a refresh of the notes data
            vm.refresNotesData++;
            // vm.calculateClientGroupStats()
            vm.getPotentialClientDuplicates();
          }
        })
        .catch(function(error) {
          console.log(error);
          vm.$notify({
            message:
              "There was a problem saving the client group. Please try again.",
            type: "warning"
          });
        });
    },

    handleEditContact(index, row) {
      this.$router.push({
        name: "Contact",
        params: {
          id: row.guid,
          client_id: this.model.client_id,
          client_guid: this.id,
          production_id: this.userSelectedProductionInt,
          company_name: this.model.company_name
        }
      });
    },

    handleNewContact() {
      this.$router.push({
        name: "Contact",
        params: {
          id: 0,
          client_id: this.model.client_id,
          client_guid: this.id,
          production_id: this.userSelectedProductionInt,
          company_name: this.model.company_name
        }
      });
    }
  }
};
</script>
<style scoped>
.el-select {
  display: block;
}

.swal2-modal {
  height: 600px;
}

/*   div.row { height: 60px; } */

div.fatrow {
  height: 60px;
}

div.superfatrow {
  height: 100px;
}

.divider div {
  height: 25px;
}

.right {
  text-align: right;
}
</style>

